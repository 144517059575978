import { SoyabeanComponent } from './soyabean/soyabean.component';
import { RiceComponent } from './rice/rice.component';
import { MaizeComponent } from './maize/maize.component';
import { WheatComponent } from './wheat/wheat.component';
import { OverviewComponent } from './overview/overview.component';

import {Routes, RouterModule} from "@angular/router";


export const routes:Routes = [
    { path: 'overview', component: OverviewComponent, pathMatch: 'full'},
    { path: 'wheat', component: WheatComponent, pathMatch: 'full'},
    { path: 'maize', component: MaizeComponent, pathMatch: 'full'},
    { path: 'rice', component: RiceComponent, pathMatch: 'full'},
    { path: 'soybean', component: SoyabeanComponent, pathMatch: 'full'}
];

export const marketMonitorRouter = RouterModule.forChild(routes);