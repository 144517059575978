import { Component, OnInit } from '@angular/core';
import { HttpService } from 'app/shared/utils';
import * as _ from 'lodash';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {

  constructor(private _httpServie: HttpService, private _spinnerService: NgxSpinnerService,) { }
  public monitorData: any[];
  public productName: string[] = [];
  public finalData: IFinalData[] = [];
  ngOnInit() {
    this._spinnerService.show();
    this.finalData = [];
      this._httpServie.getOverviewData().subscribe( (response: IOverviewData[]) => {
        this._spinnerService.hide();
        this.productName = Array.from(new Set(response.map( eachMonitorData => {return eachMonitorData.product_name;})));
        this.monitorData = _.groupBy(response, 'product_name');
        this.productName.forEach( (currentProductName: string) => {
          const allValues: { aValue: number, bValue: number, cValue: number } = this.createValueOne(this.monitorData[currentProductName]);
          const finalData: IFinalData = { element_name: currentProductName, value1: -1, value2: -1 };
          // For value1 0-Easing, 1-Tightning, -1-Neutral
          finalData.value1 = allValues.aValue > allValues.bValue ? ( allValues.aValue === allValues.bValue ? -1 : 0) : 1;
          finalData.value2 = allValues.aValue > allValues.cValue ? ( allValues.aValue === allValues.cValue ? -1 : 0) : 1;
          this.finalData.push(finalData);
        });
      }, error => { this._spinnerService.hide(); });
  }

  ngAfterViewInit() {
  }

  createValueOne(currentProductArray: any[]) {
    /**
     * From the previous month forecast, this column shows
      the comparison between "A" and "B"

      From the previous season, this column shows
      the comparison between "A" and "C"

      "A" is the highest season available and is_estimate=1
      eg. Maize 2021/22 1200.294725

      "B" is the highest season available and is_estimate=0
      eg. Maize 2021/22 1199.731417

      "C" is the lowest season available and is_estimate=1
      eg. Maize 2020/21 1157.705056

      The same logic is applied for the other commodities (Wheat, Rice and Soybeans)
     */
    let allValues = { aValue: -1, bValue: -1, cValue: -1 };
    const filteredEstimatedValue = currentProductArray.filter( (currentProductInfo: any) => { return currentProductInfo.is_estimate });
    const filteredNonEstimatedValue = currentProductArray.filter( (currentProductInfo: any) => { return !currentProductInfo.is_estimate });
    allValues.aValue =  Math.max.apply(Math, filteredEstimatedValue.map(function(o) { return o.value; }));
    allValues.bValue =  Math.max.apply(Math, filteredNonEstimatedValue.map(function(o) { return o.value; }));
    allValues.cValue =  Math.min.apply(Math, filteredEstimatedValue.map(function(o) { return o.value; }));
    console.log(allValues);
    return allValues;
  }
}

export interface IOverviewData {
  database_label: string;
  element_name: string;
  is_estimate: number;
  last_update: any;
  product_name: string;
  region_name: string;
  season: string;
  units: string;
  value: number;
}

export interface IFinalData {
  element_name: string;
  value1: number; // 0-Easing, 1-Tightning, -1-Neutral
  value2: number; // 0-Easing, 1-Tightning, -1-Neutral
}