import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { marketMonitorRouter } from './market-monitor.routing';
import { OverviewComponent } from './overview/overview.component';
import { WheatComponent } from './wheat/wheat.component';
import { MaizeComponent } from './maize/maize.component';
import { RiceComponent } from './rice/rice.component';
import { SoyabeanComponent } from './soyabean/soyabean.component';
import { HttpService } from '../shared/utils/http.service'
@NgModule({
  imports: [
    CommonModule,
    marketMonitorRouter
  ],
  declarations: [OverviewComponent, WheatComponent, MaizeComponent, RiceComponent, SoyabeanComponent],
  providers: [ HttpService ]
})
export class MarketMonitorModule { }
