import { Component, OnInit } from '@angular/core';
import {FadeZoomInTop} from "../../animations/fade-zoom-in-top.decorator";

@Component({
  selector: 'app-auth-layout',
  template: '<router-outlet></router-outlet>',
  styles: []
})
export class IndicatorsGraphLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
