import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';


@Pipe({
  name: 'notesPipe',
   pure: false
})
export class NotesPipe implements PipeTransform {
  //private apiUrl:string = 'https://faoext-765638417.eu-west-1.elb.amazonaws.com/FAOUN/'; //QA
  constructor(public http: HttpClient){}
  transform(value: any, filter:any): any {
    let tempValue = JSON.parse(JSON.stringify(value));
    const returnVal = tempValue.filter((e)=>{

      if(e['Element'] === ""){
        e['Element'] = filter.elements.map(v=>v.text).toString();
      }
      if(e['Commodity'] === ""){
        e['Commodity'] = filter.products.map(v=>v.text).toString();
      }
      if(e['Region'] === ""){
        e['Region'] = filter.regions.map(v=>v.text).toString();
      }
      if(e['Source'] === ""){
        e['Source'] = filter.sources.toString();
      }
      if(e['Season'] === ""){
        e['Season'] = filter.seasons.toString();
      }
      let elementTrue = false;
      for(let ele of filter.elements.map(v=>v.text)){
        if(e['Element'].indexOf(ele) !== -1){
          elementTrue = true;
        }
      }
      let productTrue = false;
      for(let pro of filter.products.map(v=>v.text)){
         if(e['Commodity'].indexOf(pro) !== -1){
           productTrue = true;
         }
      }
      let regionTrue = false;
      for(let reg of filter.regions.map(v=>v.text)){
         if(e['Region'].indexOf(reg) !== -1){
           regionTrue = true;
         }
      }
      let sourceTrue = false;
      for(let source of filter.sources){
         if(e['Source'].indexOf(source) !== -1){
           sourceTrue = true;
         }
      }
      let seasonTrue = false;
      for(let season of filter.seasons){
         if(e['Season'].indexOf(season) !== -1){
           seasonTrue = true;
         }
      }
      
      if(elementTrue &&  productTrue && regionTrue && sourceTrue && seasonTrue ) return true;
    });
    return returnVal;
  }
  getApi(apiUrl) {
    //this.commonComponent.showLoader();
    return new Promise((resolve, reject) => {
      try {
        this.http.get(apiUrl).subscribe(data => {
          //this.commonComponent.hideLoader();
          resolve(data);
        }, err => {
          //this.commonComponent.hideLoader();
          console.log(err);
          reject(err);
        });
      }
      catch (e) {

        reject(e);
      }
    });
  }
}
