import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import GOOGLE_NOTES from '../../../assets/exec/GOOGLE_NOTES.json';
import GOOGLE_SUPPLY from '../../../assets/exec/GOOGLE_SUPPLY.json';
import GOOGLE_DEFINITIONS from '../../../assets/exec/GOOGLE_DEFINITIONS.json';
import GOOGLE_INPUT_TOOL from '../../../assets/exec/GOOGLE_INPUT_TOOL.json';

@Injectable()
export class NotesService {
  
	notes :any;
	googleUrl = environment.GOOGLE_NOTES;
  constructor(public http: HttpClient) {
  	
  }

  getStaticJson(name){
	switch(name){
		case "GOOGLE_NOTES":
			return GOOGLE_NOTES;
		case "GOOGLE_SUPPLY":
			return GOOGLE_SUPPLY;
		case "GOOGLE_DEFINITIONS":
			return GOOGLE_DEFINITIONS;
		case "GOOGLE_INPUT_TOOL":
			return GOOGLE_INPUT_TOOL;
	}	
  }

  getApi(apiUrl) {
    //this.commonComponent.showLoader();
    
    	
	    return new Promise((resolve, reject) => {
	      try {
		      		this.http.get(apiUrl).subscribe(data => {
			          //this.commonComponent.hideLoader();
			          resolve(data);
			        }, err => {
			          //this.commonComponent.hideLoader();
			          console.log(err);
			          reject(err);
			        });
      			
  			}
	      	catch (e) {

	        	reject(e);
	      	}
	    });
    }
	getNotes(apiUrl = this.googleUrl) {
		//this.commonComponent.showLoader();
		return new Promise((resolve, reject) => {
			try {
				if (this.notes !== undefined) {
					resolve(this.notes);
				} else {
					this.http.get(apiUrl).subscribe(data => {
						//this.commonComponent.hideLoader();
						this.setNotes(data);
						resolve(data);
					}, err => {
						//this.commonComponent.hideLoader();
						console.log(err);
						reject(err);
					});
				}
			}
			catch (e) {

				reject(e);
			}
		});
	}
    
  
  setNotes(notes){
  	this.notes = notes;
  }
}