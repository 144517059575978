import { Component, OnInit } from '@angular/core';
import { HttpService } from 'app/shared/utils';
import * as _ from 'lodash';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-wheat',
  templateUrl: './wheat.component.html',
  styleUrls: ['./wheat.component.css']
})
export class WheatComponent implements OnInit {
  public sequence = {
    Trade: 4,
    'Domestic Utilization': 3,
    'Closing Stocks': 5,
    'Production': 1,
    'Supply': 2,
  }
  public monitorData: any[];
  public elementName: string[] = [];
  public finalData: IFinalData[] = [];
  public coulmnNames: string[] = [];
  public monthNames = { 1: 'Jan', 2: 'Feb', 3: 'March', 4: 'Apr', 5: 'May', 6: 'June', 7: 'July', 8: 'Aug', 9: 'Sept',
                        10: 'Oct', 11: 'Nov', 12: 'Dec'}; 
  public headerMonths = { firstHeaderName: '', secondHeaderName: ''};
  public firstRecord: IMonitorData; // This data will hold season with current year values
  public lastRecord: IMonitorData; // This data will hold season with previous year values
  constructor( private _httpServie: HttpService,  private _spinnerService: NgxSpinnerService,) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this._spinnerService.show();
    this._httpServie.getMonitorDataForWheat().subscribe( (response: IMonitorData[]) => {
      this._spinnerService.hide();
      this.firstRecord = response[0];
      this.lastRecord = response[response.length-1];
      this.headerMonths.firstHeaderName = this.createDateHeaderData (response[response.length-2]?.last_update); // second last object last_update value
      this.headerMonths.secondHeaderName = this.createDateHeaderData (response[response.length-1]?.last_update); // last object last_update value
      let seasonGroup = _.groupBy(response, 'season');
      this.coulmnNames = Object.keys(seasonGroup);
      response.map( (eachMonitorData: IMonitorData) => {
        eachMonitorData.last_update = new Date(eachMonitorData.last_update);
        eachMonitorData.last_update = eachMonitorData.last_update.toLocaleString('en-IN');
      });
      this.elementName = Array.from(new Set(response.map( eachMonitorData => {return eachMonitorData.element_name;})));
      this.monitorData = _.groupBy(response, 'element_name');
      this.finalData = this.createFinalData(this.elementName, this.monitorData);
      this.finalData.sort((a,b) => (a.sequenceNo > b.sequenceNo) ? 1 : ((b.sequenceNo > a.sequenceNo) ? -1 : 0));
    }, error => { this._spinnerService.hide(); });
  }

  createFinalData(elementName: string[], allMonitorData: object) {
    let finalData: IFinalData[] = [];
    elementName.forEach( (eachElementName: string) => {
      let currentFinalInfo: IFinalData = { element_name: '', estimate1: null, estimate2: null, estimate3: null, sequenceNo: -1};
      let currentMonitorData: IMonitorData[] = allMonitorData[eachElementName];
      currentMonitorData = _.orderBy(currentMonitorData, ['last_update'], ['desc']);
      let estimate1 = currentMonitorData.filter( (eachInfo: IMonitorData) => {
        return eachInfo.season === this.lastRecord?.season && eachInfo.is_estimate === 1;
      });

      let estimate2 = currentMonitorData.filter( (eachInfo: IMonitorData) => {
        return eachInfo.season === this.firstRecord?.season && eachInfo.is_estimate === 0;
      });

      let estimate3 = currentMonitorData.filter( (eachInfo: IMonitorData) => {
        return eachInfo.season === this.firstRecord?.season && eachInfo.is_estimate === 1;
      });
      currentFinalInfo.element_name = eachElementName;
      currentFinalInfo.estimate1 = estimate1.length && estimate1[0] ? estimate1[0].value : null;
      currentFinalInfo.estimate2 = estimate2 && estimate2[0] ? estimate2[0].value : null;
      currentFinalInfo.estimate3 = estimate3.length && estimate3[0] ? estimate3[0].value : null;
      currentFinalInfo.sequenceNo = this.sequence[currentFinalInfo.element_name];
      finalData.push(currentFinalInfo);
    });
    return finalData;
  }
  createDateHeaderData(timeStamp: number): string {
    let d = new Date(timeStamp);
    return d.getDate().toString() + ' ' + this.monthNames[(d.getMonth()+1)];
  }
}
export interface IMonitorData {
  database_label: string;
  element_name: string;
  is_estimate: number;
  last_update: any;
  product_name: string;
  region_name: string;
  season: string;
  units: string;
  value: number;
}

export interface IFinalData {
  element_name: string;
  estimate1: string | any;
  estimate2: string | any;
  estimate3: string | any;
  sequenceNo: number;
}
