/**
 * Created by griga on 7/11/16.
 */


import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from "./shared/layout/app-layouts/main-layout.component";
import { AuthLayoutComponent } from "./shared/layout/app-layouts/auth-layout.component";
import { IndicatorsGraphLayoutComponent } from "./shared/layout/app-layouts/indicators-graph-layout.component";
import { sheetsListProdComponent } from "./shared/sheetsList/sheetsList.component";
import { ModuleWithProviders } from "@angular/core";

export const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    data: { pageTitle: 'Home' },
    children: [
      {
        path: '', redirectTo: 'market-database/supply-and-demand-overview', pathMatch: 'full'
      },
      // {
      //   path: 'dashboard',
      //   loadChildren: 'app/+dashboard/dashboard.module#DashboardModule',
      //   data: {pageTitle: 'Dashboard'}
      // },
      {
        path: 'market-database',
        // :() => import('./page/page.module').then(m=>m.PageModule)}
        loadChildren: () => import('app/+market-database/market-database.module').then(m => m.MarketDatabaseModule),
        data: { pageTitle: 'Market Database' }
      },
      {
        path: 'policy-database',
        loadChildren: () => import('app/+policy-database/policy-database.module').then(m => m.PolicyDatabaseModule),
        data: { pageTitle: 'Policy Database' }
      },
      // {
      //   path: 'indicators-graph',
      //   loadChildren: 'app/+indicators/indicators.module#IndicatorsModule',
      //   data: {pageTitle: 'Indicators'}
      // },
      // {
      //   path: 'smartadmin',
      //   loadChildren: 'app/+smartadmin-intel/smartadmin-intel.module#SmartadminIntelModule',
      //   data: {pageTitle: 'Smartadmin'}
      // },
      // {
      //   path: 'app-views',
      //   loadChildren: 'app/+app-views/app-views.module#AppViewsModule',
      //   data: {pageTitle: 'App Views'}
      // },
      // {
      //   path: 'calendar',
      //   loadChildren: 'app/+calendar/calendar.module#CalendarModule'
      // },
      // // {
      // //   path: 'e-commerce',
      // //   loadChildren: 'app/+e-commerce/e-commerce.module#ECommerceModule',
      // //   data: {pageTitle: 'E-commerce'}
      // // },
      //
      // {
      //   path: 'forms',
      //   loadChildren: 'app/+forms/forms-showcase.module#FormsShowcaseModule',
      //   data: {pageTitle: 'Forms'}
      // },
      {
        path: 'graphs',
        loadChildren: () => import('app/+graphs/graphs-showcase.module').then(m => m.GraphsShowcaseModule),
        data: { pageTitle: 'Graphs' }
      }
      // {
      //   path: 'maps',
      //   loadChildren: 'app/+maps/maps.module#MapsModule',
      //   data: {pageTitle: 'Maps'}
      // },
      // {
      //   path: 'miscellaneous',
      //   loadChildren: 'app/+miscellaneous/miscellaneous.module#MiscellaneousModule',
      //   data: {pageTitle: 'Miscellaneous'}
      // },
      // {
      //   path: 'outlook',
      //   loadChildren: 'app/+outlook/outlook.module#OutlookModule',
      //   data: {pageTitle: 'Outlook'}
      // },
      // {
      //   path: 'tables',
      //   loadChildren: 'app/+tables/tables.module#TablesModule',
      //   data: {pageTitle: 'Tables'}
      // },
      // {
      //   path: 'ui',
      //   loadChildren: 'app/+ui-elements/ui-elements.module#UiElementsModule',
      //   data: {pageTitle: 'Ui'}
      // },
      // {
      //   path: 'widgets',
      //   loadChildren: 'app/+widgets/widgets-showcase.module#WidgetsShowcaseModule',
      //   data: {pageTitle: 'Widgets'}
      // },
    ]
  },

  { path: 'auth', component: AuthLayoutComponent, loadChildren: () => import('app/+auth/auth.module').then(m => m.AuthModule) },
  {
    path: '', component: IndicatorsGraphLayoutComponent,
    children: [
      {
        path: 'indicators',
        loadChildren: () => import('app/+indicators-graph/indicators-graph.module').then(m => m.IndicatorsGraphModule),
        data: { pageTitle: 'Graphs' }
      }
    ]
  },
  {
    path: 'input-tool', component: IndicatorsGraphLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/shared/sheetsList/sheetsList.module').then(m => m.SheetListModule),
        data: { pageTitle: 'Input Tool' }
      }
    ]
  },
  {
    path: 'market-monitor',
    loadChildren: () => import('app/market-monitor/market-monitor.module').then(m => m.MarketMonitorModule),
    data: { pageTitle: 'Market Monitor' }
  },
  { path: '**', redirectTo: 'miscellaneous/error404' }

];

export const routing = RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' });
